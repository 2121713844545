import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"

import gvLogoIcon from "./resources/GV_logo.svg"
import appsIcon from "./resources/apps.svg"
import searchIcon from "./resources/search.svg"
import closeIcon from "./resources/close.svg"
import recentsIcon from "./resources/recents.svg"
import avatarImage from "./resources/avatar.jpg"

import { ProfileView } from "./Profile"
import { AppsView } from "./Apps"
import { SEARCH_OPTIONS } from "./resultMap"
import { links } from "./links"

const HeaderContainer = styled.div`
	width: 100%;
	height: 60px;
	padding: 6px 26px;
	padding-right: 14px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	box-sizing: border-box;
`

const MenuIcon = styled.img`
	width: 24px;
	border-radius: 100px;
	opacity: 40%;
	margin-right: 12px;
	padding: 4px;

	:hover {
		background-color: #ebebeb;
		cursor: pointer;
	}
`

const ContentContainer = styled.div`
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
`

const Centering = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`

const Logo = styled.img`
	height: 82px;
	margin-top: 15%;
	max-width: 60%;
`

const SearchBox = styled.div`
	height: 44px;
	border-radius: 32px;
	border: 1px solid #dfe1e5;
	width: 584px;
	max-width: 584px;
	margin-top: 44px;
	display: flex;
	align-items: center;
	cursor: text;

	:hover {
		box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
	}
`

const SearchInput = styled.input`
	font-size: 14px;
	border: none;
	margin-left: 4px;
	width: 90%;
	outline: none;
`

const SearchMenuContainer = styled.div`
	position: relative;
	top: 0;
	right: 0;
	border-radius: 22px;
	width: 584px;
	max-width: 584px;
	margin-top: 44px;
	box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
	background-color: white;
	padding: 0 12px;
	box-sizing: border-box;
`

const ResultLink = styled(Link)`
	width: 100%;
	text-decoration: none;

	display: flex;
	height: 44px;
	align-items: center;
	margin: 0 -12px;
	padding: 0 12px;

	:hover {
		background-color: #f7f7f7;
	}
`

const Button = styled(Link)`
	height: 36px;
	min-width: 54px;
	margin: 29px 6px;
	padding: 0 16px;
	border-radius: 4px;
	color: #202124;
	background-color: #f8f9fa;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border: 1px solid #f8f9fa;
	text-decoration: none;

	:hover {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
		border: 1px solid #dadce0;
	}

	:active {
		border: 1px solid #5087f2;
	}
`

const ResponsiveSearchButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;

	@media only screen and (max-width: 600px) {
		display: none;
	}
`

const FooterContainerTop = styled.div`
	position: fixed;
	display: flex;
	bottom: 46px;
	left: 0;
	width: 100%;
	background-color: #f2f2f2;
	color: #70757a;
	height: 46px;
	align-items: center;
	padding: 0 30px;
	border-bottom: 1px solid #dadce0;
	box-sizing: border-box;

	@media only screen and (max-width: 600px) {
		bottom: 64px;
	}
`

const FooterContainerBottom = styled.div`
	position: fixed;
	display: flex;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 0 16px;
	background-color: #f2f2f2;
	color: #70757a;
	height: 46px;
	align-items: center;
	justify-content: space-between;
	padding: 0 30px;
	box-sizing: border-box;

	@media only screen and (max-width: 600px) {
		justify-content: center;
		display: initial;
		padding: 8px 16px;
		height: 64px;
	}
`

const LinkContainer = styled.div`
	> :not(:last-child) {
		margin-right: 30px;
	}

	@media only screen and (max-width: 600px) {
		padding: 12px
		width: 100%;
		display: flex;
		justify-content: center;
	}
`

const ResponsiveHeaderLinks = styled.div`
	@media only screen and (max-width: 600px) {
		display: none;
	}
`

const HeaderLink = styled.a`
	color: #000000;
	text-decoration: none;
	cursor: pointer;
	margin-right: 16px;

	:hover {
		text-decoration: underline;
	}
`

const FooterA = styled.a`
	color: #70757a;
	text-decoration: none;
	cursor: pointer;
	padding: 4px 0;

	:hover {
		text-decoration: underline;
	}
`

const FooterLink = styled(Link)`
	color: #70757a;
	text-decoration: none;
	cursor: pointer;
	padding: 4px 0;

	:hover {
		text-decoration: underline;
	}
`

const RecentsIcon = () => {
	return (
		<img
			style={{ width: "20px", opacity: "20%", marginLeft: "2px", marginRight: "8px" }}
			src={recentsIcon}
			alt="Lupen-Icon"
		/>
	)
}

// TODO remove Safari autocomplete
export const MainPage = () => {
	const [profileActive, setProfileActive] = useState(false)
	const [appsActive, setAppsActive] = useState(false)
	const [searchMenuActive, setSearchMenuActive] = useState(false)

	const ref = useRef<HTMLDivElement>(null)

	const handleClick = (e: any) => {
		if (ref.current?.contains(e.target)) {
			return
		}

		setAppsActive(false)
		setProfileActive(false)
		setSearchMenuActive(false)
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleClick)
		return () => {
			document.removeEventListener("mousedown", handleClick)
		}
	}, [])

	const SearchMenu = () => {
		return (
			<SearchMenuContainer ref={ref}>
				<div
					style={{
						width: "100%",
						height: "45px",
						borderBottom: "1px solid #dadce0",
						display: "flex",
						alignItems: "center",
					}}
				>
					<img
						style={{ width: "24px", marginTop: "-1px", opacity: "20%" }}
						src={searchIcon}
						alt="Lupen-Icon"
					/>
					<SearchInput autoFocus />
					<img
						style={{ width: "24px", marginTop: "-1px", opacity: "50%", cursor: "pointer" }}
						src={closeIcon}
						alt="Schließen-Icon"
						onClick={() => {
							setSearchMenuActive(false)
						}}
					/>
				</div>
				{SEARCH_OPTIONS.map((result) => {
					return (
						result.display && (
							<ResultLink to={result.tag}>
								<RecentsIcon />
								{result.text}
							</ResultLink>
						)
					)
				})}

				<Centering>
					<Button to="/all" style={{ marginTop: "16px" }}>
						Floorian Suche
					</Button>
					<Button to="/all" style={{ marginTop: "16px" }}>
						Auf gut Glück!
					</Button>
				</Centering>
			</SearchMenuContainer>
		)
	}

	const Header = () => {
		return (
			<HeaderContainer>
				<ResponsiveHeaderLinks>
					<HeaderLink href={links.mail}>Mail</HeaderLink>
					<HeaderLink href={links.linkedIn}>LinkedIn</HeaderLink>
					<HeaderLink href={links.github}>GitHub</HeaderLink>
				</ResponsiveHeaderLinks>
				<MenuIcon
					src={appsIcon}
					alt="Apps-Icon"
					onClick={() => {
						setAppsActive(!appsActive)
						setProfileActive(false)
					}}
				/>
				<img
					style={{ width: "32px", borderRadius: "100px", cursor: "pointer" }}
					src={avatarImage}
					alt="Avatar-Icon"
					onClick={() => {
						setProfileActive(!profileActive)
						setAppsActive(false)
					}}
				/>
			</HeaderContainer>
		)
	}

	return (
		<>
			<Header />
			<ContentContainer>
				<Centering>
					<Logo src={gvLogoIcon} alt="Floorian-Logo" />
				</Centering>
				<Centering>
					{!searchMenuActive && (
						<SearchBox
							onClick={() => {
								setSearchMenuActive(!searchMenuActive)
							}}
						>
							<img
								style={{ width: "24px", marginLeft: "12px", opacity: "20%" }}
								src={searchIcon}
								alt="Lupen-Icon"
							/>
							<SearchInput />
						</SearchBox>
					)}
					{searchMenuActive && <SearchMenu />}
				</Centering>
				{!searchMenuActive && (
					<ResponsiveSearchButtonContainer>
						<Button to="/all">Floorian Suche</Button>
						<Button to="/all"> Auf gut Glück!</Button>
					</ResponsiveSearchButtonContainer>
				)}
			</ContentContainer>
			<FooterContainerTop>Deutschland</FooterContainerTop>
			<FooterContainerBottom>
				<LinkContainer>
					<FooterLink to={"/all"}>Über Florian</FooterLink>
					<FooterLink to={"/projects"}>Projekte</FooterLink>
				</LinkContainer>

				<LinkContainer>
					<FooterA href={links.github}>GitHub</FooterA>
					<FooterA href={links.linkedIn}>LinkedIn</FooterA>
					<FooterA href={links.mail}>Mail</FooterA>
				</LinkContainer>
			</FooterContainerBottom>

			{profileActive && (
				<div ref={ref}>
					<ProfileView />
				</div>
			)}
			{appsActive && (
				<div ref={ref}>
					<AppsView />
				</div>
			)}
		</>
	)
}
