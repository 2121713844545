import { useEffect, useState } from "react"

import { Result } from "./Result"
import results from "./data.json"
import { ResultsDesktop } from "./ResultsDesktop"
import { ResultsMobile } from "./ResultsMobile"
import { SEARCH_OPTIONS } from "./resultMap"

// #TODO accordeon "Ähnliche Fragen"

export const Results = () => {
	const [windowWidth, setWindowWidth] = useState(0)

	useEffect(() => {
		updateDimensions()

		window.addEventListener("resize", updateDimensions)

		return () => window.removeEventListener("resize", updateDimensions)
	}, [])

	const updateDimensions = () => {
		setWindowWidth(window.innerWidth)
	}

	let resultTag = window.location.href.split("/").slice(-1)[0]
	let searchInputValue =
		SEARCH_OPTIONS.find((option) => {
			return option.tag === resultTag
		})?.text || ""

	const resultsFiltered: Result[] = results.filter((result) => result.tags.includes(resultTag))

	return (
		<>
			{windowWidth > 600 ? (
				<ResultsDesktop results={resultsFiltered} searchInputValue={searchInputValue} />
			) : (
				<ResultsMobile results={resultsFiltered} searchInputValue={searchInputValue} />
			)}
		</>
	)
}
