import styled from "styled-components"
import { Link } from "react-router-dom"

import avatarImage from "./resources/avatar.jpg"
import addPersonIcon from "./resources/addPerson.svg"
import { links } from "./links"

const ProfileCard = styled.div`
	position: absolute;
	top: 60px;
	right: 16px;
	border-radius: 8px;
	width: 328px;
	max-width: 328px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 4px 24px rgba(0, 0, 0, 0.1);
	background-color: white;
	z-index: 1;
	padding: 20px 0;
	padding-bottom: 10px;
	margin-left: 16px;
`

const Avatar = styled.img`
	border-radius: 100px;
	width: 72px;
	height: 72px;
`

const Centering = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`

const MailLink = styled.a`
	color: #929292;
	text-decoration: none;
`

const RoundButton = styled.a`
	border: 1px solid #d5d5d5;
	border-radius: 100px;
	cursor: pointer;
	padding: 8px 16px;
	margin: 20px 0;
	color: black;
	text-decoration: none;

	:hover {
		background-color: #f7f7f7;
	}

	:active {
		background-color: #e4e4e4;
		box-shadow: 0 1px 6px rgb(32 33 36 / 30%);
	}
`

const WideButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 48px;
	border-top: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
	cursor: pointer;
	color: black;
	text-decoration: none;

	:hover {
		background-color: #f7f7f7;
	}

	:active {
		background-color: #e4e4e4;
	}
`

const RectangularButton = styled.a`
	border: 1px solid #d5d5d5;
	border-radius: 4px;
	cursor: pointer;
	padding: 10px 24px;
	margin: 20px 0;
	color: black;
	text-decoration: none;

	:hover {
		background-color: #f7f7f7;
	}

	:active {
		background-color: #e4e4e4;
		box-shadow: 0 1px 6px rgb(32 33 36 / 30%);
	}
`

const TinyLink = styled.a`
	display: flex;
	align-items: center;
	font-size: 12px;
	color: #929292;
	text-decoration: none;
	cursor: pointer;
	padding: 6px 10px;
	border-radius: 4px;

	:hover {
		background-color: #f7f7f7;
	}

	:active {
		background-color: #ececec;
	}
`

const TinyRouterLink = styled(Link)`
	display: flex;
	align-items: center;
	font-size: 12px;
	color: #929292;
	text-decoration: none;
	cursor: pointer;
	padding: 6px 10px;
	border-radius: 4px;

	:hover {
		background-color: #f7f7f7;
	}

	:active {
		background-color: #ececec;
	}
`

const WideButtonIcon = styled.img`
	width: 20px;
	opacity: 0.5;
	margin-right: 10px;
`

export const ProfileView = () => {
	return (
		<ProfileCard>
			<Centering>
				<Avatar src={avatarImage} />
			</Centering>
			<Centering style={{ marginTop: "16px", fontSize: "16px" }}>Florian Bogner</Centering>
			<Centering>
				<MailLink href={links.mail}>kontakt@florian-bogner.de</MailLink>
			</Centering>
			<Centering>
				<RoundButton href={links.linkedIn}>Zu LinkedIn-Konto</RoundButton>
			</Centering>
			<Link to="/about" style={{ textDecoration: "none" }}>
				<WideButton>
					<WideButtonIcon src={addPersonIcon} />
					Mehr über Florian
				</WideButton>
			</Link>
			<Centering>
				<RectangularButton href={links.github}>GitHub</RectangularButton>
			</Centering>
			<div style={{ borderBottom: "1px solid #ebebeb", marginBottom: "8px" }} />
			<Centering>
				<TinyLink href={links.github}>Code anzeigen</TinyLink>{" "}
				<div style={{ padding: "8px 0", color: "#929292" }}>•</div>{" "}
				<TinyRouterLink to="/legal">Impressum</TinyRouterLink>
			</Centering>
		</ProfileCard>
	)
}
