import { Link } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { Result } from "./Result"

import gvLogoIcon from "./resources/GV_logo.svg"
import searchIcon from "./resources/search.svg"
import avatarImage from "./resources/avatar.jpg"
import { ProfileView } from "./Profile"
import { SEARCH_OPTIONS } from "./resultMap"

const Centering = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`

const Header = styled.div`
	box-sizing: border-box;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 12px;
	padding-top: 16px;
	background-color: white;
`

const Logo = styled.img`
	height: 24px;
	cursor: pointer;
`
const AvatarImage = styled.img`
	width: 32px;
	border-radius: 100px;
`

const AvatarPlaceholder = styled.div`
	min-width: 32px;
	min-height: 32px;
`

const SearchBox = styled.div`
	height: 44px;
	border-radius: 32px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 1px 6px rgb(32 33 36 / 15%);
	margin: 16px;

	:hover {
		box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
	}
`

const SearchInput = styled.input`
	font-size: 14px;
	border: none;
	margin-left: 16px;
	width: 80%;
	outline: none;
`

const Menu = styled.div`
	width: 100%;
	border-bottom: 1px solid #f7f7f7;
	margin-top: 16px;
`

const ResultTitle = styled.a`
	font-size: 20px;
	color: #1558d6;
	text-decoration: none;

	:hover {
		text-decoration: underline;
	}
`

const MobileSeparator = styled.div`
	height: 6px;
	width: 100vw;
	margin-left: -16px;
	background-color: #f7f7f7;
	border-top: 1px solid #ebebeb;
	border-bottom: 1px solid #ebebeb;
`

const RelatedQueriesHeader = styled.div`
	margin-top: 14px;
	margin-bottom: 16px;
	font-size: 20px;
	font-weight: bold;
`

const RelatedQuery = styled.div`
	display: flex;
	align-items: center;

	> :not(:last-child) {
		border-bottom: 1px solid #f7f7f7;
	}
`

interface ResultsMobileProps {
	results: Result[]
	searchInputValue: string
}

const QueryLink = styled(Link)`
	width: 100%;
	text-decoration: none;

	display: flex;
	height: 44px;
	align-items: center;
	margin: 0 -12px;
	padding: 0 12px;
`

// #javascript  add footer

export function ResultsMobile({ results, searchInputValue }: ResultsMobileProps) {
	const [profileActive, setProfileActive] = useState(false)

	const ref = useRef<HTMLDivElement>(null)

	const handleClick = (e: any) => {
		if (ref.current?.contains(e.target)) {
			return
		}

		setProfileActive(false)
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleClick)
		return () => {
			document.removeEventListener("mousedown", handleClick)
		}
	}, [])

	return (
		<>
			<Header>
				<AvatarPlaceholder />
				<Centering>
					<Link to="/">
						<Logo src={gvLogoIcon} alt="Floorian-Logo" />
					</Link>
				</Centering>
				<AvatarImage
					src={avatarImage}
					alt="Avatar-Icon"
					onClick={() => {
						setProfileActive(!profileActive)
					}}
				/>
			</Header>
			<SearchBox>
				<SearchInput value={searchInputValue} />
				<img style={{ width: "24px", marginRight: "12px", opacity: "20%" }} src={searchIcon} alt="Lupen-Icon" />
			</SearchBox>
			<Menu />
			<div style={{ margin: "16px" }}>
				{results.map((result) => (
					<>
						<div style={{ margin: "16px 0" }} key={result.title}>
							<div style={{ color: "#70757a", fontSize: "12px", marginBottom: "10px" }}>
								{result.linkPrev}
							</div>
							<div style={{ marginBottom: "10px" }}>
								<ResultTitle href={result.link}>{result.title}</ResultTitle>
							</div>
							<div style={{ color: "#4D5156" }}>{result.body}</div>
						</div>
						<MobileSeparator />
					</>
				))}
				<RelatedQueriesHeader>Ähnliche Suchanfragen</RelatedQueriesHeader>

				{SEARCH_OPTIONS.map((result) => {
					if (result.text !== searchInputValue) {
						return (
							result.display && (
								<RelatedQuery>
									<QueryLink to={result.tag}>
										<img
											style={{
												width: "18px",
												marginLeft: "12px",
												marginRight: "24px",
												opacity: "40%",
											}}
											src={searchIcon}
											alt="Lupen-Icon"
										/>
										{result.text}
									</QueryLink>
								</RelatedQuery>
							)
						)
					} else {
						return null
					}
				})}
			</div>

			{profileActive && (
				<div ref={ref}>
					<ProfileView />
				</div>
			)}
		</>
	)
}
