import { useRef, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import gvLogoIcon from "./resources/GV_logo.svg"
import searchIcon from "./resources/search.svg"
import { Result } from "./Result"

import appsIcon from "./resources/apps.svg"
import avatarImage from "./resources/avatar.jpg"

import { ProfileView } from "./Profile"
import { AppsView } from "./Apps"
import { useEffect } from "react"

const Header = styled.div`
	box-sizing: border-box;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 24px;
	background-color: white;
`

const Logo = styled.img`
	margin-left: 24px;
	height: 30px;
	cursor: pointer;
`

const MenuIcon = styled.img`
	width: 24px;
	border-radius: 100px;
	opacity: 40%;
	margin-right: 12px;
	padding: 4px;

	:hover {
		background-color: #ebebeb;
		cursor: pointer;
	}
`

const SearchBox = styled.div`
	height: 44px;
	border-radius: 32px;
	width: 690px;
	max-width: 690px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0 1px 6px rgb(32 33 36 / 15%);
	margin: 0 24px;
	cursor: text;

	:hover {
		box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
	}

	@media only screen and (max-width: 980px) {
		width: 400px;
		max-width: 400px;
	}
`

const SearchInput = styled.input`
	font-size: 14px;
	border: none;
	margin-left: 16px;
	width: 80%;
	outline: none;
`

const Menu = styled.div`
	width: 100%;
	border-bottom: 1px solid #dadce0;
	margin-top: 24px;
`

const ResultsContainer = styled.div`
	margin-top: 16px;
	margin-right: 24px;
	margin-left: 180px;
	width: 652px;
	max-width: 652px;
	min-height: calc(100vh - 236px);
	line-height: 1.5;

	@media only screen and (max-width: 980px) {
		margin-left: 24px;
	}
`

const ResultTitle = styled.a`
	padding-top: 5px;
	font-size: 20px;
	color: #1a0dab;
	text-decoration: none;

	:hover {
		text-decoration: underline;
	}
`

const FooterDesktop = styled.div`
	background-color: #f2f2f2;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 16px;
`

const FooterContent = styled.div`
	display: flex;
	align-items: center;
	margin-right: 24px;
	margin-left: 180px;
	color: #70757a;
	font-size: 15px;
	white-space: nowrap;

	@media only screen and (max-width: 980px) {
		margin-left: 24px;
	}
`

const FooterLink = styled.a`
	text-decoration: none;
	color: #70757a;

	:hover {
		cursor: pointer;
		color: black;
	}
`

interface ResultsDesktopProps {
	results: Result[]
	searchInputValue: string
}

// TODO fix closing of cards when clicking on icon twice
// TODO add related queries
// TODO add menu

export function ResultsDesktop({ results, searchInputValue }: ResultsDesktopProps) {
	const [lookupTime] = useState(Math.random().toFixed(2))
	const [profileActive, setProfileActive] = useState(false)
	const [appsActive, setAppsActive] = useState(false)

	const ref = useRef<HTMLDivElement>(null)

	const handleClick = (e: any) => {
		if (ref.current?.contains(e.target)) {
			return
		}

		setAppsActive(false)
		setProfileActive(false)
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleClick)
		return () => {
			document.removeEventListener("mousedown", handleClick)
		}
	}, [])

	return (
		<>
			<Header>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Link to="/">
						<Logo src={gvLogoIcon} alt="Floorian-Logo" />
					</Link>
					<SearchBox>
						<SearchInput value={searchInputValue} />
						<img
							style={{ width: "24px", marginRight: "12px", opacity: "20%" }}
							src={searchIcon}
							alt="Lupen-Icon"
						/>
					</SearchBox>
				</div>
				<div style={{ marginRight: "24px", minWidth: "76px", width: "76px" }}>
					<MenuIcon
						src={appsIcon}
						alt="Apps-Icon"
						onClick={() => {
							setAppsActive(!appsActive)
							setProfileActive(false)
						}}
					/>
					<img
						style={{ width: "32px", borderRadius: "100px", cursor: "pointer" }}
						src={avatarImage}
						alt="Avatar-Icon"
						onClick={() => {
							setProfileActive(!profileActive)
							setAppsActive(false)
						}}
					/>
				</div>
			</Header>
			<Menu />
			<ResultsContainer>
				<div style={{ color: "#70757a", marginBottom: "16px" }}>
					Ungefähr {results.length} Ergebnisse ({lookupTime} Sekunden)
				</div>
				{results.map((result) => (
					<div style={{ marginBottom: "30px" }} key={result.title}>
						<div style={{ color: "#70757a" }}>{result.linkPrev}</div>
						<ResultTitle href={result.link}>{result.title}</ResultTitle>
						<div>{result.body}</div>
					</div>
				))}
			</ResultsContainer>

			<FooterDesktop>
				<FooterContent>
					Deutschland <div style={{ margin: "0 14px", minHeight: "40px", borderLeft: "1px solid #d1d1d1" }} />
					<div
						style={{
							backgroundColor: "#19bb3c",
							minHeight: "10px",
							minWidth: "10px",
							borderRadius: "100px",
							marginRight: "4px",
						}}
					/>
					<div
						style={{ cursor: "pointer" }}
						onClick={() => {
							window.alert(
								"Selbstverstänlich wird hier weder dein Standort noch deine IP-Adresse verarbeitet oder gespeichert. Das sind nur Dummys für den authentischen Google-Look 😊",
							)
						}}
					>
						<span style={{ color: "black", fontWeight: "bold" }}>Neuhausen-Nymphenburg München </span>
						<span style={{ color: "#1a73e8", marginLeft: "4px" }}>- Über deine IP-Adresse</span>
						<span style={{ color: "#1a73e8", marginLeft: "4px" }}>- Standort aktualisieren</span>
					</div>
				</FooterContent>
				<div style={{ borderBottom: "1px solid #d1d1d1", marginBottom: "14px" }} />
				<FooterContent>
					<FooterLink href={"mailto:kontakt@florian-bogner.de"} style={{ marginRight: "24px" }}>
						Hilfe
					</FooterLink>
					<FooterLink href={"mailto:kontakt@florian-bogner.de"}>Feedback geben</FooterLink>
				</FooterContent>
			</FooterDesktop>

			{profileActive && (
				<div ref={ref}>
					<ProfileView />
				</div>
			)}
			{appsActive && (
				<div ref={ref}>
					<AppsView />
				</div>
			)}
		</>
	)
}
