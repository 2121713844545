type SearchResult = {
    tag: string
    text: string
    display: boolean
}

export const SEARCH_OPTIONS: SearchResult[] = [
    {
        tag: "all",
        text: "alles über dich",
        display: true
    },
    {
        tag: "projects",
        text: "projekte",
        display: true
    },
    {
        tag: "contact",
        text: "wie erreiche ich dich",
        display: true
    },
    {
        tag: "cv",
        text: "lebenslauf",
        display: true
    },
    {
        tag: "legal",
        text: "rechtliches",
        display: false
    }
]