import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import { MainPage } from "./MainPage"
import { createGlobalStyle } from "styled-components"
import { Results } from "./Results"
import { SEARCH_OPTIONS } from "./resultMap"

const GlobalStyle = createGlobalStyle`
	box-sizing: border-box;
	
	 body {
		margin: 0;
		font-family: 'Arial', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-size: 14px;
  }
`

ReactDOM.render(
	<React.StrictMode>
		<GlobalStyle />
		<Router>
			<Switch>
				{SEARCH_OPTIONS.map((result) => {
					return (
						<Route path={"/" + result.tag}>
							<Results />
						</Route>
					)
				})}
				<Route path="/">
					<MainPage />
				</Route>
			</Switch>
		</Router>
	</React.StrictMode>,
	document.getElementById("root"),
)
