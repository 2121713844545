import styled from "styled-components"
import { Link } from "react-router-dom"

import searchIcon from "./resources/suche.svg"
import mailIcon from "./resources/mail.svg"
import projectsIcon from "./resources/projects.png"
import cvIcon from "./resources/cv.svg"
import linkedinIcon from "./resources/linkedin.svg"
import githubIcon from "./resources/github.svg"
import avatarImage from "./resources/avatar.jpg"
import cv from "./resources/cv.pdf"
import { links } from "./links"

const AppsCard = styled.div`
	position: absolute;
	top: 60px;
	right: 16px;
	border-radius: 8px;
	width: 328px;
	max-height: 448px;
	max-width: 328px;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 4px 24px rgba(0, 0, 0, 0.1);
	background-color: white;
	z-index: 1;
	padding: 16px;
	margin-left: 16px;
`

const AppGrid = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	column-gap: 16px;
	row-gap: 16px;
`

const AppTile = styled(Link)`
	justify-self: stretch;
	cursor: pointer;
	border-radius: 10px;
	padding: 16px 0;
	color: #000000;
	text-decoration: none;

	:hover {
		background-color: rgb(233, 240, 253);
		cursor: pointer;
	}
`

const AppTileA = styled.a`
	justify-self: stretch;
	cursor: pointer;
	border-radius: 10px;
	padding: 16px 0;
	color: #000000;
	text-decoration: none;

	:hover {
		background-color: rgb(233, 240, 253);
		cursor: pointer;
	}
`

const AppTileD = styled.div`
	justify-self: stretch;
	cursor: pointer;
	border-radius: 10px;
	padding: 16px 0;
	color: #000000;
	text-decoration: none;

	:hover {
		background-color: rgb(233, 240, 253);
		cursor: pointer;
	}
`

const Centering = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`

const AppIcon = styled.img`
	width: 40px;
	margin-bottom: 10px;
`

// TODO fix cv link here and IN JSON
export const AppsView = () => {
	return (
		<AppsCard>
			<AppGrid>
				<AppTileD
					onClick={() => {
						window.location.reload()
					}}
				>
					<Centering>
						<AppIcon src={searchIcon} />
					</Centering>
					<Centering>Suche</Centering>
				</AppTileD>
				<AppTile to="/about">
					<Centering>
						<img
							src={avatarImage}
							style={{ borderRadius: "100px", width: "40px", marginBottom: "10px" }}
							alt="Bild Florian"
						/>
					</Centering>
					<Centering>Florian</Centering>
				</AppTile>
				<AppTileA href={cv}>
					<Centering>
						<AppIcon src={cvIcon} />
					</Centering>
					<Centering>CV</Centering>
				</AppTileA>
				<AppTileA href={links.mail}>
					<Centering>
						<AppIcon src={mailIcon} />
					</Centering>
					<Centering>Mail</Centering>
				</AppTileA>
				<AppTile to="/projects">
					<Centering>
						<AppIcon src={projectsIcon} />
					</Centering>
					<Centering>Projekte</Centering>
				</AppTile>
				<AppTileA href={links.linkedIn}>
					<Centering>
						<AppIcon src={linkedinIcon} />
					</Centering>
					<Centering>LinkedIn</Centering>
				</AppTileA>
				<AppTileA href={links.github}>
					<Centering>
						<AppIcon src={githubIcon} />
					</Centering>
					<Centering>GitHub</Centering>
				</AppTileA>
			</AppGrid>
		</AppsCard>
	)
}
